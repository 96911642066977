<template>
  <div>
	<!-- 转账 -->
	<common-head url="/assets" :title="$t('liaotian.transferAccounts')" :is-return="true" @onclickRight="onclickRight">
		<img slot="right" src="@/assets/imgs/icon/icon_search.png" alt="" />
	</common-head>
	
	<div class="layoutBox bgGray">
		<div class="ulList">
			<div class="list-cell" @click="showPicker = true">
				{{ token }}
				<div class="right">
					<span class="mainFontLightColor">{{ $t("tba.choosebi") }}</span>
					<i class="icon_arrR" style="margin: 0;"></i>
				</div>
			</div>
		</div>
		<div class="contWhiteBox">
			<div class="list-cell-item">
				<div class="hd">
					<span class="mainFontColor">{{ $t('tba.reciveAccount')}}</span>
				</div>
				<div class="bd">
					<input type="text" class="inp" v-model="dataForm.username" @input="checkAccount" :placeholder="$t('tba.reciveAccountName')" />
					<!-- <input type="text" class="inp" :placeholder="$t('tba.reciveAccountName')" /> -->
					<!-- <div class="scanBtn" @click="scan"></div> -->
				</div>
			</div>
			<div class="list-cell-item">
				<div class="hd">
					<span class="mainFontColor">{{ $t('myPledge.Number')}}</span>
					<span class="mainFontLighterColor">{{ $t('pledge.usable')}} {{token}} : {{ total }}</span>
				</div>
				<div class="bd">
					<input type="text" id="Number" class="inp" v-model="dataForm.amount" @input="setArrival" :placeholder="$t('myPledge.Number')" />
					<div class="allBtn mainColor1" @click="fullWithdraw">{{ $t('pledge.all')}}</div>
				</div>
			</div>
			<div class="list-cell-item">
				<div class="hd">
					<span class="mainFontColor">{{ $t('tba.Handlingfee')}}（{{token}}）</span>
				</div>
				<div class="bd">
					{{ dataForm.fee }}
				</div>
			</div>
		</div>
		<div class="contWhiteBox">
			<div class="list-cell-item">
				<div class="hd">
					<span class="mainFontColor">{{ $t('tba.Arrivalquantity')}}（{{token}}）</span>
				</div>
				<div class="bd">
					{{ dataForm.arrival }}
				</div>
			</div>
		</div>
		<div class="comTips" style="margin-bottom: 1.4rem;">
			<p>{{ $t('tba.zuyi')}}{{transfer_min}}usdt,{{$t('tba.shouxufei')}}{{ dataForm.fee }}{{$t('tba.shouxufei_danwei')}}</p>
		</div>
		<div class="comBtnStyle" v-if="NumberType && usernameType" @click="confirmTransfer()">{{ $t('xinzeng.zhuanz') }}</div>
		<div class="comBtnStyle disabled" v-else>{{ $t('xinzeng.zhuanz') }}</div>
	</div>
	
	<!-- 币种选择 -->
	<van-action-sheet
		  v-model="showPicker"
		  :round="false"
		  :actions="periodChoiceSet"
		  @select="onSelectPeriod"
		></van-action-sheet>
	
  </div>
</template>

<script>
import commonHead from '@/components/commonHead';
export default {
	name: "transfer",
  components: { commonHead },
  data() {
    return {
      isVerificationShow1: false,
      info: "",

      inputValue: "",
      inputCode: "",
      haveValue: false,
      haveCode: false,
      transfer_min: 0,
      sid: "", //session id
      nation: "86", //country code
      userInput: false,
      errorInputShow: false,
      codeSending: false,
      theTime: 60,
      total: 0,
      fee: 2,
	  fee_ratio: 0,
      plus: 0,
      dataForm: {
        fee: 0,
        username: "",
        tradepwd: "",
        sid: "",
        code: "",
        phone: "",
        amount: "",
        arrival: 0,
        txid: "",
        token: "USDT",
        mainchain: "",
      },
	  // 20200411
	  token: "USDT",
	  token_set: ['USDT'],
	  mainchain_set: [],
	  // 20210415----------
	  showPicker: false,
	  periodChoiceSet: [{ name: 'USDT' }],
	  NumberType: false,
	  usernameType: false,
	  fee_type: '',
    };
  },
  mounted() {
    this.prepareTransfer();
	// -----20200411[start]-----
	this.getSupportedTokens();
	this.setArrival();
	// -----20200411[end]-----
  },
  created() {
    if (window.plus) {
      this.plusReady();
    } else {
      document.addEventListener("plusready", this.plusReady, false); //
    }
  },
  methods: {
	  onclickRight() {
	  		  this.$router.push({
	  			  path: '/walletRecord',
	  			  query: {
	  				  // cashType: 'transfer_in'
	  				  cashType: 'transfer_out'
	  			  }
	  		  })
	  },
	  
	  // 初始化-转账数据
	  prepareTransfer() {
	    this.$post2("Pay/Api/Index/prepareTransferConfig", {})
	      .then((res) => {
	        console.log(res);
	        this.dataForm.fee = res.fee;
	        this.total = res.amount;
	        this.transfer_min = res.transfer_min;
	        this.dataForm.txid = res.txid;
	        this.dataForm.amount = 0;
	        this.dataForm.arrival = 0;
	        this.fee_type = res.fee_type;
	        if (res.fee_type == "fixed") {
	          this.fee = res.fee + "USDT";
	        } else {
	          this.fee = res.fee_ratio * 100 + "%";
	        }
			// console.log('dataForm', this.dataForm)
	      })
	      .catch((e) => {
	        this.$toast.fail(e);
	      });
	  },
	  
	  onSelectPeriod(item) {
	  		  this.showPeriodChoice = false;
	  		  this.token = item.name;
	  		  this.showPicker = false;
	  },
	  
	  // ----------20200410[start]----------
	  choicetoken(e) {
	    console.log(e.token);
	    this.token = e.token;
	    this.getDepositAddress();
	    this.id = e.id;
	    this.show = false;
	  },
	  choice(e) {
	    this.token = e.token;
	    this.getDepositAddress();
	    this.id = e.id;
	  },
	  // 获取地址
	  getDepositAddress() {
	    this.$post2("Pay/Api/Index/getDepositAddress", {
	      token: this.token,
	      mainchain: this.dataForm.mainchain,
	    })
	      .then((res) => {
	        this.dataForm.address = res.address;
	      })
	      .catch((err) => {
	        this.$dialog.alert({
	          message: this.$t("xinzeng.buzhichi"),
	        });
	      });
	  },
	  //获取支持的币种
	  getSupportedTokens() {
	    this.$post2("Pay/Api/Index/getSupportedTokens")
	      .then((res) => {
	        this.mainchain_set = res.mainchain_set;
	        this.token_set = res.token_set;
	        this.dataForm.mainchain = res.mainchain_set[0];
	        console.log(this.dataForm.mainchain);
	        this.getDepositAddress();
	      })
	      .catch((err) => {
	        this.$toast("请检查网络");
	      });
	  },
	  // ----------20200410[end]----------
    plusReady() {
      var ws = plus.webview.currentWebview(); //pw回车可输出plus.webview
    },

    getImage() {
      let cmr = plus.camera.getCamera(); // 获取摄像头对象
      let res = cmr.supportedImageResolutions[0]; // 字符串数组，摄像头支持的拍照分辨率
      let fmt = cmr.supportedImageFormats[0]; // 字符串数组，摄像头支持的拍照文件格式
      console.log("Resolution :" + res + ", Format: " + fmt);
      cmr.captureImage(
        (path) => {
          alert("调用成功: " + path);
        },
        (error) => {
          // 拍照操作失败的回调函数
          alert("调用失败: " + error.message);
        },
        { resolution: res, format: fmt } // 摄像头拍照参数
      );
    },
    scan() {
      //console.log(window.TPUBox);

      this.dataForm.username = this.$scan();
    },

    reset() {
      this.prepareTransfer();
    },
    setArrival() {
		// let v = this.dataForm.amount - this.dataForm.fee;
		// if (v >= 0) {
		//   this.dataForm.arrival = v.toFixed(2);
		// 		this.NumberType = true
		// } else {
		// 		  this.NumberType = false
		// }
		
		let amount = this.dataForm.amount
		let total = this.total
		if (amount > this.total) {
			this.$toast('超出可用余额')
			this.dataForm.amount = total
			amount = total
		}
		
		console.log('dataForm', this.dataForm)
		
		if (this.fee_type == 'fixed') {
			// this.fee = res.fee + 'USDT';
				// 固定费用
				let v = amount - this.dataForm.fee;
				if (v > 0) {
					this.dataForm.arrival = Math.floor(v *100)/100;
					this.NumberType = true
				} else {
					this.dataForm.arrival = 0;
					this.NumberType = false
				}
		} else {
			// this.fee = res.fee_ratio * 100 + '%';
				// 按照提币数量*比例计算
				let v = amount - amount * this.fee_ratio;
				this.dataForm.arrival = Math.floor(v *100)/100;
				let fee2 = amount - this.dataForm.arrival;
				this.dataForm.fee = Math.floor(fee2 *100)/100;
				if (v > 0) {
					this.NumberType = true
				} else {
					this.NumberType = false
				}
		}
		
      
    },
    fullWithdraw() {
      this.dataForm.amount = this.total;
      this.setArrival();
    },
    // 获取验证码
    verificationCode() {
      this.haveValue = false;
      if (this.codeSending) {
        return;
      }

      this.theTime = 60;
      this.codeSending = true;
      this.doCountDown();

      this.$post2("Security/User/authsmscodeV2", {})
        .then((res) => {
          this.dataForm.sid = res.sid;
          console.log();
        })
        .catch((e) => {
          this.$toast.fail(e);
        });
    },
    doCountDown() {
      if (this.theTime > 1) {
        this.theTime--;
        this.codeTimer = setTimeout(() => {
          this.doCountDown();
        }, 1000);
      } else {
        this.codeSending = false;
        this.haveValue = true;
      }
    },

    // 申请提现
    // prepareTransfer() {
    //   this.$post2("Pay/Api/Index/prepareTransferConfig", {})
    //     .then((res) => {
    //       console.log(res);
    //       this.dataForm.fee = res.fee;
    //       this.total = res.amount;
    //       this.transfer_min = res.transfer_min;
    //       this.dataForm.txid = res.txid;
    //       this.dataForm.amount = 0;
    //       this.dataForm.arrival = 0;
    //       if (res.fee_type == "fixed") {
    //         this.fee = res.fee + "USDT";
    //       } else {
    //         this.fee = res.fee_ratio * 100 + "%";
    //       }
    //     })
    //     .catch((e) => {
    //       this.$toast.fail(e);
    //     });
    // },
	
	
	checkAccount() {
		let form = this.dataForm;
		if (form.username == '') {
			this.usernameType = false
		} else {
			this.usernameType = true
		}
	},
	
	// 确认转账
    confirmTransfer() {
		if (Number(this.dataForm.amount) < this.transfer_min) {
			this.$toast(this.$t('tba.Minimumwithdrawalamount') + this.transfer_min)
			document.getElementById('Number').focus()
			return
		}
		
		this.$router.push({
			path: 'checkTransferVerifyCode',
			query: {
				dataForm: this.dataForm
			}
		})
		return
		
      let form = this.dataForm;

      form.tradepwd = this.$SHA256(form.tradepwd);
	  
      this.$post2("Pay/Api/Index/directConfirmTransfer", form)
        .then((res) => {
          console.log(res);
          this.dataForm.tradepwd = "";
          this.$toast.success("转账成功");
        })
        .catch((e) => {
          this.dataForm.tradepwd = "";
          this.$toast.fail(e);
        });
    },
    onClickLeft() {
      this.$router.push("assets");
    },
    routerToQueRen() {
      this.$router.push("");
    },
  },
};
</script>

<style scoped lang="less">
.inputSpan {
  font-size: 0.3rem;
  color: #ff4b04;
  padding-top: 0.2rem;
  float: right;
}

/* .inputSpan {
      font-size: 0.2rem;
      color: #ECBB34;
    } */
.fdStyle {
  background: #f1f1f1;
  height: 100%;
  /* padding: 0.3rem; */
}

.van-ellipsis {
  color: #ffffff !important;
  /* background: green; */
}

.van-nav-bar {
  background: #ecbb34;
}

.van-icon {
  color: #ffffff;
}

.endList {
  margin-top: 2.3rem;
}

.btnDivStyle {
  margin-top: 0.7rem;
  margin-bottom: 0.1rem;
  text-align: center;
}

.bodyStyle {
  background: #f1f1f1;
  height: 100%;
}

.vanCellStyle1 {
  /* margin-left: 0.4rem;
        margin-right: 0.4rem; */
  border-radius: 5px;
  margin-top: 0.2rem;
  /* background: #f1f1f1; */
}

.vanCellStyle2 {
  /* margin-left: 0.4rem;
        margin-right: 0.4rem; */
  border-radius: 5px;
  margin-top: 0.3rem;
  /* background: #f1f1f1; */
}

.van-icon-arrow-left {
  color: #ffffff;
}

.van-hairline--bottom {
  background: #ecbb34;
  color: #ffffff;
}

.van-nav-bar__title {
  background: #ecbb34;
  color: #ffffff;
}

/* .van-ellipsis {
      background: 3d8051;
    } */

.ulList {
  /* margin: 0.3rem; */
  /* padding: 0.3rem;
  background: #ffffff;
  border-radius: 5px; */
}

.inputPapaStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #f2f2f2;
}

.inputStyle {
  border: 0px;
  /* border-top: 0px;
        border-left: 0px;
        border-right: 0px; */
  width: 100%;
  /* border-bottom: 1px solid #f2f2f2; */
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}

.whole {
  background: #ffffff;
}

.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}

.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}

.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}


// ----------20200411[start]-----------
.layoutBox {
	// min-height: calc(100vh - 1.76rem);
	min-height: calc(100vh - .88rem);
	padding: 0 .3rem .5rem;
}

.ulList {
  padding: .1rem 0;
  .list-cell {
	  height: .88rem;
	  line-height: .88rem;
	  padding: 0 .25rem;
	  background: #FFFFFF;
	  border-radius: .12rem;
	  margin-bottom: .1rem;
	  position: relative;
	  .right {
		  position: absolute;
		  z-index: 1;
		  right: .25rem;
		  bottom: 0;
	  }
  }
}

.contWhiteBox {
	background: #FFFFFF;
	border-radius: .12rem;
	margin-bottom: .25rem;
}
.list-cell-item {
	padding: .2rem .25rem;
	border-bottom: 1px solid #EDEDED;
	line-height: .45rem;
	&:last-child {
		border-bottom: 0;
	}
	.hd {
		font-size: .24rem;
		display: flex;
		justify-content: space-between;
		margin-bottom: .25rem;
	}
	.bd {
		display: flex;
		justify-content: space-between;
		position: relative;
	}
	.inp {
		font-size: .28rem;
		height: .45rem;
		display: block;
		width: 100%;
		flex: 1;
		border: 0;
		outline: 0;
	}
	input::-webkit-input-placeholder,
	input:-moz-placeholder,
	input::-moz-placeholder,
	input:-ms-input-placeholder {
		color: #999999;
	}
	.scanBtn {
		width: .45rem;
		height: .45rem;
		background: url(../../assets/imgs/icon/icon_scancode.png) no-repeat center center;
		background-size: .4rem .4rem;
	}
}
.comTips {
	color: #999999;
	font-size: .24rem;
	line-height: .45rem;
}

// ----------20200411[end]-----------
</style>
