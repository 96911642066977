<template>
	<div class="container">
		<common-head url="/assets2" :title="$t('tba.walletRecord')" :is-return="true"></common-head>

		<div class="tabBox bgWhite">
			<div class="commonTabtit">
				<ul class="type">
					<!-- <li
						v-for="(item, index) in filterSet"
						:key="index"
						 :class="cashActive == index ? 'active' : ''"
						 @click="handleTabType(index)"
						>
						{{item}}
					</li> -->
					<li :class="cashActive == 0 ? 'active' : ''" @click="handleTabType(0)">{{ $t('pledge.all') }}</li>
					<li :class="cashActive == 1 ? 'active' : ''" @click="handleTabType(1)">{{ $t('xinzeng.chongbi') }}</li>
					<li :class="cashActive == 2 ? 'active' : ''" @click="handleTabType(2)">{{ $t('xinzeng.tibi') }}</li>
					<li :class="cashActive == 3 ? 'active' : ''" @click="handleTabType(3)">{{ $t('tba.transfer_in') }}</li>
					<li :class="cashActive == 4 ? 'active' : ''" @click="handleTabType(4)">{{ $t('tba.transfer_out') }}</li>
				</ul>
			</div>
			
			<div class="bottomDIvStyle">
				<van-list @load="onLoadcashFlow" v-model="billLoading" :finished="billFinished" :finished-text="$t('cashFlow.NoMore')" :loading-text="$t('common.loading')">
					<van-row class="vanRowBoderStyle" v-for="(item, index) in billList" :key="index">
						<van-col class="bottomDivRowColStyle" span="20">
							<p class="mainFontLighterColor bottomDivRowColStyleP2">{{ item.update_at }}</p>
							<p class="bottomDivRowColStylePS">{{ item.desc }}</p>
						</van-col>
						<van-col span="4">
							<p class="vanCol4PStyle">
								<span :class="item.amount > 0 ? 'mainColor1' : 'mainColor3'">{{ item.amount }}</span>
							</p>
						</van-col>
					</van-row>
				</van-list>
			</div>

			<!-- <tab-bared></tab-bared> -->
		</div>
	</div>
</template>

<script>
import Loading from '@/components/Loading';
import tabBar from '@/components/tabBar/tabBar.vue';
import commonHead from '@/components/commonHead';

export default {
	name: 'walletRecord',
	components: {
		Loading,
		tabBar,
		commonHead,
	},
	data() {
		return {
			billLoading: false,
			billFinished: false,
			billData: [],
			billList: [],
			billPage: 1,

			listData: [],
			assetsDitale: 0,
			rewardData: 0,
			amount: '',
			frozen: '',
			filterSet: [],
			showPicker: false,

			items: [],
			top: 1,
			bottom: 1,
			// ---------20200410[start]----------
			active: 0,
			// 全部''/充币'deposit'/提币'withdraw'/转入'transfer_in'/转出'transfer_out'
			cashtypeSet: ['', 'deposit', 'withdraw', 'transfer_in', 'transfer_out'], //01234
			// cashType: '',
			cashType: '',
			cashActive: 0,
		};
	},

	// filters: {
	// 	amountIsMinus(amount) {
	// 		if (amount.substr(0, 1) == '-') {
	// 			return true;
	// 		} else {
	// 			return false;
	// 		}
	// 	}
	// },

	// watch: {
	// 	take(news, old) {
	// 		this.billPage = 1;
	// 		this.billFinished = false;
	// 		this.billList = [];
	// 	}
	// },

	created() {
		this.billPage = 1;
		// this.returnCashIndex()
	},
	mounted() {
		// this.getFilterSet()
		// this.getFilterSet(), this.getAssets();
		// for (var i = 1; i <= 20; i++) {
		//   this.items.push(i + " - keep walking, be 2 with you.");
		// }
		// this.top = 1;
		// this.bottom = 20;
		//this.getRobbt();
		// this.getSupportedTokens()
	},
	methods: {
		// 滚动加载列表
		onLoadcashFlow() {
			this.getCashflow();
			// 异步充值记录
			setTimeout(() => {
			  for (let i = 0; i < this.billData.length; i++) {
			    this.billList.push(this.billData[i]);
			  }
			  // console.log(this.shouyiList);
			  // 加载状态结束
			  this.billLoading = false;
			  // 数据全部加载完成
			
			  console.log(this.billData);
			  if (this.billData.length < 1) {
			    this.billFinished = true;
			  }
			}, 1000);
		},
		
		// 初始化列表-获取列表
		getCashflow() {
			this.$post2('Pay/Api/Index/cashflow', {
				token: 'USDT',
				cashType: this.cashType,
				page: this.billPage++
			}).then(res => {
				this.billData = res.list;
				// for (let i = 0; i < res.list.length; i++) {
				// 	this.billList.push(res.list[i]);
				// }
				// // 加载状态结束
				// this.billLoading = false;
				// // 数据全部加载完成
		
				// if (res.list.length < 1) {
				// 	this.billFinished = true;
				// }
			});
		},
		// returnCashIndex() {
		// 	for (let i = 0; i < this.cashtypeSet.length; i++) {
		// 		if (this.cashtypeSet[i] == this.cashType) {
		// 			this.cashActive = i
		// 		}
		// 	}
		// },
		// 切换类型-全部''/充币'deposit'/提币'withdraw'/转入'transfer_in'/转出'transfer_out'
		// handleTabType(cashType) {
		// 	this.cashType = cashType
		handleTabType(cashActive) {
			this.cashActive = cashActive
			this.cashType = this.cashtypeSet[cashActive]
			
			this.billPage = 1;
			this.billFinished = false;
			this.billList = [];
			this.billData = [];
			this.billLoading = true;
			this.onLoadcashFlow();
		},
		
		// refresh(done) {
		// 	setTimeout(() => {
		// 		this.billPage = 1;
		// 		this.getCashflow();
		// 		// var start = this.top - 1;

		// 		// for (var i = start; i > start - 10; i--) {
		// 		//   this.items.splice(0, 0, i + " - keep walking, be 2 with you.");
		// 		// }
		// 		// this.top = this.top - 10;
		// 		done();
		// 	}, 1500);
		// },
		// infinite(done) {
		// 	this.getCashflow();

		// 	setTimeout(() => {
		// 		var start = this.bottom + 1;
		// 		for (var i = start; i < start + 10; i++) {
		// 			this.items.push(i + ' - keep walking, be 2 with you.');
		// 		}
		// 		this.bottom = this.bottom + 10;

		// 		done();
		// 	}, 1500);
		// },
		//methods中的方法
		// changeConfirm(val, index) {
		// 	this.billPage = 1;
		// 	this.cashType = val; //传值
		// 	console.log(index); //索引
		// 	this.showPicker = false;
		// 	console.log(this.cashType);
		// 	this.getCashflow();
		// 	// 如果最开始的数据源是数组对象，我的做法是先把数据源变成一位数组(用forEach遍历每一项，push到一个新数组中)，用于展示，然后在confirm事件中，拿到索引，根据索引就可以拿到数组中所对应的对象
		// },

		// getFilterSet() {
		// 	this.$post2('Pay/Api/Index/getFilterSet').then(res => {
		// 		console.log(res);
		// 		this.filterSet = res;
		// 	});
		// },
		// 前往互转页面
		// transfer() {
		// 	this.$router.push('transfer');
		// },
		// exchange_tt() {
		// 	this.$router.push('switch');
		// },
		// 获取资产信息
		// getAssets() {
		// 	this.$post2('User/Api/Index/userAsset').then(res => {
		// 		console.log(res);
		// 		// 总资产是 amout + frozen

		// 		let t = res.USDT.amount + res.USDT.frozen;
		// 		this.assetsDitale = t.toFixed(2);
		// 		// let t2 = res.REWARD.amount + res.REWARD.frozen;
		// 		// this.rewardData = t2.toFixed(2);
		// 		// console.log(typeof res.USDT.amount)
		// 		// this.amount =  res.amount
		// 		// + res.frozen
		// 	});
		// },

		// 获取 量化交易
		// getRobbt() {
		// 	this.$post('Robot/Api/Index/getTokenPair').then(res => {
		// 		console.log(res);
		// 	});
		// }
	}
};
</script>

<style scoped lang="less">
.vanRowBoderStyle {
  border-bottom: 1px solid #EDEDED;
  padding: 0.15rem 0 .2rem;
  
  .vanCol4PStyle {
    font-size: 0.28rem;
    text-align: right;
    margin-top: 0.25rem;
	span {
		  font-family: Bahnschrift;
	}
  }
  
  .bottomDivRowColStylePS {
    font-size: .28rem;
    line-height: .4rem;
    margin-top: .08rem;
  }
  
  .bottomDivRowColStyleP2 {
    font-size: 0.24rem;
    line-height: .35rem;
  }
  
}

.commonTabtit {
		color: #373737;
		font-size: .24rem;
		height: .8rem;
		border-bottom: 1px solid #EDEDED;
		margin-bottom: .2rem;
		.type {
			display: flex;
			justify-content: space-between;
			align-items: center;
			li {
				line-height: .8rem;
				// margin-right: .5rem;
				&.active {
					font-size: .28rem;
					font-weight: bold;
					border-bottom: 2px solid #90C4FF;
				}
			}
		}
	}
</style>
